import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { Global } from "@emotion/core";
import { Header } from "./header";
import { Body } from "./body";
import { Footer } from "./footer";
import { globalStyling } from "../layout/global-styling";
import * as TypographySystem from "./typography-system";
import { SEO } from "./seo";

interface LayoutProps {
    children: React.ReactNode;
    pageContext: {
        frontmatter: {
            title: string;
        };
    };
}

const Layout: React.FC<LayoutProps> = ({ children, pageContext }) => {
    return (
        <MDXProvider
            components={{
                h1: TypographySystem.H1
            }}
        >
            <SEO title={`${pageContext.frontmatter.title}`} />
            <Global styles={globalStyling} />
            <Header />
            <Body>{children}</Body>
            <Footer />
        </MDXProvider>
    );
};

export default Layout;
