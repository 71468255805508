import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Arrow } from "./arrow";

const HomeLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    text-decoration: none;

    svg {
        fill: rgb(28, 107, 186);
        margin-right: 1rem;
    }

    em {
        color: rgb(28, 107, 186);
    }

    &:hover {
        svg {
            fill: rgb(20, 20, 20);
            transform: translateX(-0.5rem);
        }
        em {
            color: rgb(20, 20, 20);
        }
    }
`;

export const Body: React.FC = ({ children }) => (
    <div
        css={css`
            margin-top: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: #fff;
        `}
    >
        <div
            css={css`
                height: 3rem;
            `}
        />
        <div
            css={css`
                width: 90%;
                max-width: 700px;
                margin: 0 auto;
            `}
        >
            <HomeLink
                getProps={({ isCurrent }) =>
                    isCurrent ? { style: { display: "none" } } : {}
                }
                to="/"
            >
                <Arrow direction="left" size={12} />
                <strong>Tillbaka till Start</strong>
            </HomeLink>
            {children}
        </div>
    </div>
);
