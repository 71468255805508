import React from "react";
import { css } from "@emotion/core";
import { Rainbow } from "./rainbow";

export const Footer: React.FC = () => (
    <>
        <div
            css={css`
                margin-top: 0;
                height: 100px;
                background-color: #fff;
            `}
        />
        <Rainbow />
    </>
);
