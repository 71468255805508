import { css } from "@emotion/core";
import * as breakpoints from "./breakpoints";

export const globalStyling = css`
    * {
        box-sizing: border-box;
        margin: 0;
    }
    * + * {
        margin-top: 1rem;
    }

    html,
    body {
        margin: 0;
        background-color: #f9e8da;
        font-family: "Volvo Novum", Arial, sans-serif;

        > div {
            margin-top: 0;
        }
    }

    p {
        font-weight: 300;
        font-size: 1rem;
        line-height: 32px;
        color: rgb(20, 20, 20);
        margin: 1rem 0 1rem;
    }

    strong {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5;
        margin: 0;
    }

    small {
        font-weight: 300;
        letter-spacing: 0.02em;
        font-size: 0.75rem;
        margin: 0;
        display: block;
    }

    h1 {
        font-weight: 500;
        font-size: 3rem;

        ${breakpoints.fromM} {
            font-size: 3.5rem;
        }
    }

    h3 {
        font-weight: 500;
        font-size: 2rem;
        margin-top: 2rem;
        color: rgb(20, 20, 20);
    }

    h4 {
        font-weight: 500;
        font-size: 1.25rem;
        margin-top: 3rem;
    }
`;
