import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "@emotion/styled";
import Img from "gatsby-image/withIEPolyfill";
import { Rainbow } from "./rainbow";

const Container = styled.div`
    width: 100%;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    box-shadow: 0px 0px 20px #00000019;
    border-bottom: 4px solid #eee;
    z-index: 100;

    margin: 0;

    * {
        margin: 0;
    }
`;

const Logo = styled(Img)`
    width: 30%;
    max-width: 300px;
    margin: 1.5rem 0;
`;

const Seperator = styled.hr`
    width: 0;
    height: 40px;
    border: 1px solid #eee;
`;

export const Header: React.FC = () => {
    const images = useStaticQuery(imageQuery);

    return (
        <>
            <Rainbow />
            <Container>
                <Logo
                    fluid={images.childhood.sharp.fluid}
                    objectFit="contain"
                />
                <Seperator />
                <Logo fluid={images.volvo.sharp.fluid} objectFit="contain" />
            </Container>
        </>
    );
};

const imageQuery = graphql`
    query {
        childhood: file(relativePath: { eq: "childhood-logo.png" }) {
            sharp: childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        volvo: file(relativePath: { eq: "volvo-logo.png" }) {
            sharp: childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
