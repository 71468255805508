export const onlyS = "@media (max-width: 479px)";
export const untilM = "@media (max-width: 479px)";
export const fromM = "@media (min-width: 480px)";
export const onlyM = "@media (min-width: 480px) and (max-width: 1023px)";
export const untilL = "@media (max-width: 1023px)";
export const fromL = "@media (min-width: 1024px)";
export const onlyL = "@media (min-width: 1024px) and (max-width: 1599px)";
export const untilXL = "@media (max-width: 1599px)";
export const fromXL = "@media (min-width: 1600px)";
export const onlyXL = "@media (min-width: 1600px)";
