import React from "react";
import { css } from "@emotion/core";

export const H1: React.FC = ({ children }) => (
    <>
        <h1>{children}</h1>
        <hr
            css={css`
                border: 2px solid #eee;
                margin-bottom: 2rem;
            `}
        />
    </>
);
