import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "@emotion/styled";
import BackgroundImage from "gatsby-background-image";

const RainbowBg = styled(BackgroundImage)`
    width: 100%;
    height: 15px;
    background-size: 800px 15px;
    background-repeat: repeat;
    margin: 0;
    z-index: 10;
`;

export const Rainbow: React.FC = () => {
    const image = useStaticQuery(rainbowQuery);
    return <RainbowBg fluid={image.rainbow.sharp.fluid} />;
};

const rainbowQuery = graphql`
    query {
        rainbow: file(relativePath: { eq: "rainbow-bar.jpg" }) {
            sharp: childImageSharp {
                fluid(maxHeight: 15) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
